﻿import $ from "jquery";
import { type ssoData } from "../models/sso-data";
import { ContactLeadData } from "../models/contact-lead-data";
import { type simpleDto } from "../models/simple-dto";

export default class SSO {
    static ssoData?: ssoData;
    static cookieDomain: string;
    static tagSelector: string = "data-ssotag";
    static globalCodes: any | undefined;

    public static init() {
        $("form").on("submit", SSO.handleSubmit);

        SSO.globalCodes = window["GlobalCodes"];
        var ssojson = SSO.getCookie("sso");
        if (ssojson) {
            try {
                SSO.ssoData = JSON.parse(ssojson);
            } catch {
                SSO.ssoData = {}
            }
        }
        else {
            SSO.ssoData = {};
        }

        SSO.fillFields();

        var domainParts = window.location.hostname.split(".");
        if (domainParts.length < 3) {
            SSO.cookieDomain = window.location.hostname;
        }
        else {
            SSO.cookieDomain = domainParts.slice(domainParts.length - 2).join('.');
        }
    }

    public static storeContactLeadData(data: ContactLeadData) {
        if (!SSO.ssoData) {
            SSO.ssoData = {};
        }

        if (data.firstName != "") {
            SSO.ssoData.firstName = data.firstName;
        }

        if (data.lastName != "") {
            SSO.ssoData.lastName = data.lastName;
        }

        if (data.companyName != "") {
            SSO.ssoData.companyErp = data.companyName;
        }

        if (data.emailAddress != "") {
            SSO.ssoData.email = data.emailAddress;
        }

        if (data.emailAddress != "") {
            SSO.ssoData.companyEmail = data.emailAddress;
        }

        if (data.zip != "") {
            SSO.ssoData.zipCode = data.zip;
        }

        if (data.languageCode != "") {
            SSO.ssoData.language = data.languageCode;
        }

        if (data.activity != "") {
            SSO.ssoData.activity = data.activity;
            SSO.ssoData.activityId = data.sectorId;
        }

        SSO.saveCookie();
    }

    public static loadContactLeadData(): ContactLeadData | null {
        if (!SSO.ssoData) {
            return null;
        }

        var data: ContactLeadData = {
            pageTitle: "",
            firstName: SSO.ssoData.firstName ?? "",
            lastName: SSO.ssoData.lastName ?? "",
            emailAddress: SSO.ssoData.companyEmail ?? "",
            companyName: SSO.ssoData.companyErp ?? "",
            zip: SSO.ssoData.zipCode ?? "",
            activity: SSO.ssoData.activity ?? "",
            sectorId: SSO.ssoData.activityId ?? 0,
            languageCode: SSO.ssoData.language ?? "",
            buildmat: ""
        };
        return data;
    }

    private static handleSubmit(e: JQuery.SubmitEvent) {
        const $form = $(e.currentTarget);
        const $taggedFields = $form.find("[" + SSO.tagSelector + "]");

        if (!SSO.ssoData) {
            SSO.ssoData = {};
        }

        for (var field of $taggedFields) {
            const tag = field.attributes[SSO.tagSelector].value;
            const value = (field as HTMLInputElement).value;

            switch (tag) {
                case "lastname":
                    SSO.ssoData.lastName = value;
                    break;
                case "firstname":
                    SSO.ssoData.firstName = value;
                    break;
                case "associated_company_erp":
                    SSO.ssoData.companyErp = value;
                    break;
                case "activity":
                    SSO.storeActivity(value);
                    break;
                case "email":
                    SSO.ssoData.email = value;
                    break;
                case "company_email":
                    SSO.ssoData.companyEmail = value;
                    break;
                case "zip":
                    SSO.ssoData.zipCode = value;
                    break;
                case "city":
                    SSO.ssoData.city = value;
                    break;
                case "phone":
                    SSO.ssoData.phone = value;
                    break;
                case "mobile":
                    SSO.ssoData.mobile = value;
                    break;
                case "preferred_language":
                    SSO.ssoData.language = value;
                    break;
                case "vat_number":
                    SSO.ssoData.vatNumber = value;
                    break;
                case "country":
                    SSO.storeCountry(value);
                    break;
                case "website":
                    SSO.ssoData.website = value;
                    break;
                case "function":
                    SSO.storeFunction(value);
                    break;
                case "client_number_erp":
                    SSO.ssoData.customerCode = value;
                    break;
            }
        }

        SSO.saveCookie();
    }

    private static saveCookie() {
        var json = JSON.stringify(SSO.ssoData);
        json = json.replace(";", ",");  // ; is invalid inside a cookie
        document.cookie = "sso" + "=" + json + "; expires=Sun, 1 Jan 2040 03:14:15 UTC; SameSite=lax; Secure; path=/; domain=" + SSO.cookieDomain;
    }

    private static fillFields() {
        const $taggedFields = $("[" + SSO.tagSelector + "]");

        if (!SSO.ssoData) {
            return;
        }

        for (var field of $taggedFields) {
            const tag = field.attributes[SSO.tagSelector].value;

            const input = field as HTMLInputElement;
            var select = field as HTMLSelectElement;

            switch (tag) {
                case "lastname":
                    if (input) input.value = SSO.ssoData.lastName ?? "";
                    break;
                case "firstname":
                    if (input) input.value = SSO.ssoData.firstName ?? "";
                    break;
                case "associated_company_erp":
                    if (input) input.value = SSO.ssoData.companyErp ?? "";
                    break;
                case "activity":
                    if (select) select.value = SSO.ssoData.activityId?.toString() ?? "";
                    break;
                case "email":
                    if (input) input.value = SSO.ssoData.email ?? "";
                    break;
                case "company_email":
                    if (input) input.value = SSO.ssoData.companyEmail ?? "";
                    break;
                case "zip":
                    if (input) input.value = SSO.ssoData.zipCode ?? "";
                    break;
                case "city":
                    if (input) input.value = SSO.ssoData.city ?? "";
                    break;
                case "phone":
                    if (input) input.value = SSO.ssoData.phone ?? "";
                    break;
                case "mobile":
                    if (input) input.value = SSO.ssoData.mobile ?? "";
                    break;
                case "preferred_language":
                    if (input) input.value = SSO.ssoData.language ?? "";
                    break;
                case "vat_number":
                    if (input) input.value = SSO.ssoData.vatNumber ?? "";
                    break;
                case "country":
                    if (select) select.value = SSO.ssoData.countryId?.toString() ?? "";
                    break;
                case "website":
                    if (input) input.value = SSO.ssoData.website ?? "";
                    break;
                case "function":
                    if (select) select.value = SSO.ssoData.functionId?.toString() ?? "";
                    break;
                case "client_number_erp":
                    if (input) input.value = SSO.ssoData.customerCode ?? "";
                    break;
            }
        }
    }

    private static storeCountry(value: string) {
        if (!SSO.ssoData) {
            SSO.ssoData = {};
        }

        SSO.ssoData.countryId = parseInt(value);
        if (SSO.globalCodes.countryCodes) {
            if (Array.isArray(SSO.globalCodes.countryCodes)) {
                const countryCodes: simpleDto[] = SSO.globalCodes.countryCodes;
                const found = countryCodes.find((item) => item.id === SSO.ssoData?.countryId ?? 0);
                if (typeof found !== "undefined") {
                    SSO.ssoData.country = found.name;
                }
            }
        }
    }

    private static storeActivity(value: string) {
        if (!SSO.ssoData) {
            SSO.ssoData = {};
        }

        SSO.ssoData.activityId = parseInt(value);
        if (SSO.globalCodes.sectorCodes) {
            if (Array.isArray(SSO.globalCodes.sectorCodes)) {
                const sectorCodes: simpleDto[] = SSO.globalCodes.sectorCodes;
                const found = sectorCodes.find((item) => item.id === SSO.ssoData?.activityId ?? 0);
                if (typeof found !== "undefined") {
                    SSO.ssoData.activity = found.name;
                }
            }
        }
    }

    private static storeFunction(value: string) {
        if (!SSO.ssoData) {
            SSO.ssoData = {};
        }

        SSO.ssoData.functionId = parseInt(value);
        if (SSO.globalCodes.functionCodes) {
            if (Array.isArray(SSO.globalCodes.functionCodes)) {
                const functionCodes: simpleDto[] = SSO.globalCodes.functionCodes;
                const found = functionCodes.find((item) => item.id === SSO.ssoData?.functionId ?? 0);
                if (typeof found !== "undefined") {
                    SSO.ssoData.function = found.name;
                }
            }
        }
    }

    private static getCookie(name: string): string | null {
        const nameLength = (name.length + 1);
        return document.cookie
            .split(';')
            .map(c => c.trim())
            .filter(cookie => {
                return cookie.substring(0, nameLength) === `${name}=`;
            })
            .map(cookie => {
                return decodeURIComponent(cookie.substring(nameLength));
            })[0] || null;
    }
}
