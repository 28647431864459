﻿import $ from "jquery";
import axios, { AxiosError } from "axios";
import CustomDatePicker from "../components/custom-date-picker";
import Toaster from "../components/toaster";
import { TransferDateData } from "../models/basket";
import { DatePickerData } from "../models/date-picker";
import { BootstrapBreakpoint } from "../models/enums";
import CustomHelper from "../utils/custom-helper";
import Multilang from "../utils/multilang";
import ActionUrls from "../utils/action-urls";

export default class Checkout {
    private static DatesAvailableSelector: string = ".js-checkout-transfer-date--available";
    private static DatesNotAvailableSelector: string = ".js-checkout-transfer-date--not-available";

    private static DeliveryAdviceSelector: string = ".js-checkout-information--delivery-advice";
    private static TransferTypeSelector: string = ".js-checkout-information--transfer-type";

    private static TransferSubTypeSelector: string = ".js-checkout-information--transfer-subtype";

    private static CheckoutTotalsSelector: string = ".js-checkout-totals";

    public static init() {
        Checkout.initClearBasket();
        Checkout.initChangeTransferType();
        Checkout.initChangeDeliveryAdvice();
        Checkout.initChangeDeliveryAddress();
        Checkout.initChangeContact();
        Checkout.initCheckoutButtonHandler();
        Checkout.initStoreDeliveryOption();
        Checkout.initDatepickerChange();

        // Initial setting of options:
        Checkout.handleStoreDeliveryAddressChange(null);

        if (Checkout.currentStep() === 2) {
            setTimeout(() => { Checkout.updateTransferDatesReal(); }, 500);
        }
    }

    public static deleteBasketLines(basketLineIdList: Array<number>) {
        for (let basketLineId of basketLineIdList) {
            const $basketLine = $(`.js-checkout-basket-line[data-nt-basket-line-id="${basketLineId}"]`)
            $basketLine.remove();
        }
    }

    public static setLineCount(lineCount: number) {
        $(".js-checkout-order-box--basket-line-count").text(lineCount);
    }

    public static updateBasketTotals(needRefresh: boolean) {
        if (needRefresh) {
            document.location.reload();
            return;
        }

        $(Checkout.CheckoutTotalsSelector).toggleClass("nt-blur-3", true);

        const url = ActionUrls.getUrl("get-checkout-totals") + `?step=${Checkout.currentStep()}`;

        axios
            .get(url)
            .then((res) => res.data)
            .then((data: any) => {
                const $full = $("<div/>").append(data);
                const newContent = $full.find(Checkout.CheckoutTotalsSelector);

                if (typeof newContent.html() === "undefined") {
                    toastr.error(Multilang.getTranslation("checkout.message.error.update-info", "Could not update info."));
                    return;
                }

                $(Checkout.CheckoutTotalsSelector).html(newContent.html());
            }).catch((err) => {
                Toaster.showAxiosMessage(err, Multilang.getTranslation("checkout.message.error.update-info", "Could not update info."));
            }).finally(() => {
                $(Checkout.CheckoutTotalsSelector).toggleClass("nt-blur-3", false);
            });
    }

    public static updateBasketLineInfo(basketLineId: number, newLinePriceGross: string | null, newLinePriceNet: string | null, newQuantity: number | null) {
        const $basketLine = $(`.js-checkout-basket-line[data-nt-basket-line-id="${basketLineId}"]`);

        if ($basketLine.length === 0) {
            return;
        }

        if (newLinePriceGross !== null) {
            const $displayLinePriceGross = $basketLine.find(".js-checkout-basket-line--line-price-gross");
            $displayLinePriceGross.text(newLinePriceGross as string);
        }

        if (newLinePriceNet !== null) {
            const $displayLinePriceNet = $basketLine.find(".js-checkout-basket-line--line-price-net");
            $displayLinePriceNet.text(newLinePriceNet as string);
        }

        if (newQuantity !== null) {
            const $inputQuantity = $basketLine.find(".js-product-order-box--order-quantity");
            $inputQuantity.val(newQuantity);
            $inputQuantity.data("nt-original-quantity", newQuantity);
        }

        // check for gross price visibility (when equal to net price)
        if (newLinePriceNet !== null && newLinePriceGross !== null) {
            const $linePriceGrossContainer: JQuery<HTMLElement> = $basketLine.find(".js-checkout-basket-line--line-price-gross-container");
            $linePriceGrossContainer.toggleClass("d-none", newLinePriceNet === newLinePriceGross);
        }
    }

    private static currentStep(): number {
        const $indicator = $(".js-checkout-step-indicator");

        if ($indicator.length != 1) {
            // console.warn("Step Indicator not found");
            return 0;
        }

        return $indicator.data("nt-step") as number;
    }

    private static initCheckoutButtonHandler() {
        const spinner = "<div class='spinner-border spinner-border-sm my-1' role='status'><span class='visually-hidden'>Loading...</span></div>";
        // disable the buttons once one is clicked to prevent double-clicks
        if (Checkout.currentStep() === 3 || Checkout.currentStep() === 1) {
            const $form = $(Checkout.CheckoutTotalsSelector).closest("form");
            $form.on("submit", () => { $(Checkout.CheckoutTotalsSelector + " button").prop("disabled", true).html(spinner); });
        }
        else if (Checkout.currentStep() === 2) {
            const $form = $(Checkout.CheckoutTotalsSelector).closest("form");
            $form.on("submit", () => {
                $form.validate();
                if ($form.valid()) {
                    $(Checkout.CheckoutTotalsSelector + " button").prop("disabled", true).html(spinner);
                }
            });
        }
    }

    private static initClearBasket() {
        $("body").on("click", ".js-checkout--clear-basket", e => {
            e.preventDefault();
            e.stopPropagation();

            const $clearLink = $(e.currentTarget);

            bootbox.confirm({
                message: Multilang.getTranslation("checkout.label.clear-basket", "Wenst u het winkelmandje te verwijderen?"),
                buttons: {
                    confirm: {
                        label: Multilang.getTranslation("checkout.action.confirm-clear-basket", "Verwijderen"),
                        className: 'btn-primary'
                    },
                    cancel: {
                        label: Multilang.getTranslation("checkout.action.cancel-clear-basket", "Annuleren"),
                        className: 'btn-secondary'
                    }
                },
                callback: (result: boolean) => {
                    if (!result) {
                        return;
                    }

                    axios
                        .post($clearLink.prop("href") as string)
                        .then((res) => res.data)
                        .then((data: any) => {
                            document.location.reload();
                        }).catch((err: AxiosError) => {
                            const defaultMessage = Multilang.getTranslation("checkout.message.error.clear-basket", "Could not clear basket.");
                            Toaster.showAxiosMessage(err, defaultMessage);
                        }).finally(() => {
                            // do nothing
                        });
                }
            });
        });
    }

    private static initChangeDeliveryAdvice() {
        $(".js-checkout-information").on("change", Checkout.DeliveryAdviceSelector, Checkout.updateDeliveryAdvice);
    }

    private static initChangeTransferType() {
        $(".js-checkout-information").on("change", Checkout.TransferTypeSelector, Checkout.updateTransferType);
    }

    private static updateDeliveryAdvice(e) {
        Checkout.updateTransferDates(e);
    }

    private static updateTransferType(e) {
        Checkout.updateTransferDates(e);
    }

    private static updateTransferDates(e) {
        const $transferType = $(Checkout.TransferTypeSelector);
        const $transferTypeSelection = $transferType.find("option:selected");

        const $deliveryAddress = $(".js-checkout-information--delivery-address");
        const $pickupAddress = $(".js-checkout-information--pickup-address");

        const $deliveryDateTitle = $(".js-checkout-transferdate--title-delivery");
        const $pickupDateTitle = $(".js-checkout-transferdate--title-pickup");

        const transferTypeDefinition = $transferTypeSelection.data("nt-transfer-type-definition");

        if (transferTypeDefinition === "Delivery") {
            Checkout.updateDeliveryAddress($deliveryAddress, $pickupAddress);
            $pickupDateTitle.toggleClass("d-none", true);
            $deliveryDateTitle.toggleClass("d-none", false);
        }
        else if (transferTypeDefinition === "Pickup") {
            Checkout.updatePickupAddress($transferTypeSelection, $deliveryAddress, $pickupAddress);
            $deliveryDateTitle.toggleClass("d-none", true);
            $pickupDateTitle.toggleClass("d-none", false);
        }

        Checkout.updateTransferDatesReal();
    }

    private static initChangeDeliveryAddress() {
        const $checkoutInformation = $(".js-checkout-information");

        $checkoutInformation.on("change", ".js-checkout-information--delivery-address-option", e => {
            const $deliveryAddressOption = $(e.currentTarget);
            const $deliveryAddressSelection = $deliveryAddressOption.find("option:selected");

            const nameTarget = ".js-checkout-information--delivery-address-name";
            const streetTarget = ".js-checkout-information--delivery-address-street";
            const postalCodeTarget = ".js-checkout-information--delivery-address-postal-code";
            const cityTarget = ".js-checkout-information--delivery-address-city";
            const countryTarget = ".js-checkout-information--delivery-address-country";
            const languageTarget = ".js-checkout-information--delivery-address-language";
            const phoneTarget = ".js-checkout-information--delivery-address-phone";
            const emailTarget = ".js-checkout-information--delivery-address-email";

            Checkout.resetInfoField(nameTarget);
            Checkout.resetInfoField(streetTarget);
            Checkout.resetInfoField(postalCodeTarget);
            Checkout.resetInfoField(cityTarget);
            Checkout.resetInfoField(countryTarget);
            Checkout.resetInfoField(languageTarget);
            Checkout.resetInfoField(phoneTarget);
            Checkout.resetInfoField(emailTarget);

            const infoAsJson = $deliveryAddressSelection.data("nt-info") as string;
            if (typeof infoAsJson === "undefined") {
                return;
            }

            Checkout.setInfoField(nameTarget, infoAsJson["name"], infoAsJson["name"]);
            Checkout.setInfoField(streetTarget, infoAsJson["street"], infoAsJson["street"]);
            Checkout.setInfoField(postalCodeTarget, infoAsJson["postalCode"], infoAsJson["postalCode"]);
            Checkout.setInfoField(cityTarget, infoAsJson["city"], infoAsJson["city"]);
            Checkout.setInfoField(countryTarget, infoAsJson["countryId"], infoAsJson["countryName"]);
            Checkout.setInfoField(languageTarget, infoAsJson["languageId"], infoAsJson["languageName"]);
            Checkout.setInfoField(phoneTarget, infoAsJson["phone"], infoAsJson["phone"]);
            Checkout.setInfoField(emailTarget, infoAsJson["email"], infoAsJson["email"]);

            Checkout.updateTransferDatesReal();
        });

        $checkoutInformation.on("change", ".js-checkout-information--delivery-address-postal-code--input", e => {
            Checkout.updateTransferDatesReal();
        });

        $checkoutInformation.on("change", ".js-checkout-information--delivery-address-postal-country--input", e => {
            Checkout.updateTransferDatesReal();
        });
    }

    private static initChangeContact() {
        $(".js-checkout-information").on("change", ".js-checkout-information--contact-option", e => {
            const $contactOption = $(e.currentTarget);
            const $contactSelection = $contactOption.find("option:selected");

            const firstNameTarget = ".js-checkout-information--contact-first-name";
            const lastNameTarget = ".js-checkout-information--contact-last-name";
            const phoneTarget = ".js-checkout-information--contact-phone";
            const mobileTarget = ".js-checkout-information--contact-mobile";
            const genderTarget = ".js-checkout-information--contact-gender";
            const emailTarget = ".js-checkout-information--contact-email";
            const languageTarget = ".js-checkout-information--contact-language";
            const functionTarget = ".js-checkout-information--contact-function";

            Checkout.resetInfoField(firstNameTarget);
            Checkout.resetInfoField(lastNameTarget);
            Checkout.resetInfoField(phoneTarget);
            Checkout.resetInfoField(mobileTarget);
            Checkout.resetInfoField(genderTarget);
            Checkout.resetInfoField(emailTarget);
            Checkout.resetInfoField(languageTarget);
            Checkout.resetInfoField(functionTarget);

            const infoAsJson = $contactSelection.data("nt-info") as string;
            if (typeof infoAsJson === "undefined") {
                return;
            }

            Checkout.setInfoField(firstNameTarget, infoAsJson["firstName"], infoAsJson["firstName"]);
            Checkout.setInfoField(lastNameTarget, infoAsJson["lastName"], infoAsJson["lastName"]);
            Checkout.setInfoField(phoneTarget, infoAsJson["phone"], infoAsJson["phone"]);
            Checkout.setInfoField(mobileTarget, infoAsJson["mobile"], infoAsJson["mobile"]);
            Checkout.setInfoField(genderTarget, infoAsJson["genderId"], infoAsJson["genderName"]);
            Checkout.setInfoField(emailTarget, infoAsJson["email"], infoAsJson["email"]);
            Checkout.setInfoField(languageTarget, infoAsJson["languageId"], infoAsJson["languageName"]);
            Checkout.setInfoField(functionTarget, infoAsJson["functionId"], infoAsJson["functionName"]);
        });
    }

    private static initStoreDeliveryOption() {
        // read from local storage
        $(".js-checkout-information--delivery-address-option").on("change", Checkout.handleStoreDeliveryAddressChange);

        // register listener
        $(".js-checkout-delivery-option").on("click", Checkout.handleStoreDeliveryOption);
    }

    private static handleStoreDeliveryAddressChange(e) {
        if ($(".js-checkout-information--delivery-address-option").length === 0) {
            return;
        }

        const deliveryAddressId = $(".js-checkout-information--delivery-address-option").val();

        const craneOption = window.localStorage.getItem("crane" + deliveryAddressId);
        const forkliftOption = window.localStorage.getItem("forklift" + deliveryAddressId);
        const trailerOption = window.localStorage.getItem("trailer" + deliveryAddressId);

        function setRadios(radioName: string, value: string | null) {
            let selector = `input[name='${radioName}']`;

            if (value === null) {
                $(selector).prop('checked', false);
            } else if (value === "false" || value === "true") {
                selector = `input[name='${radioName}'][value='${value}']`;
                $(selector).prop('checked', true);
            } else if (craneOption === "true") {
                $(selector).prop('checked', false);
            }
        }

        setRadios("CraneAvailable", craneOption);
        setRadios("ForkliftAvailable", forkliftOption);
        setRadios("AccessibleForTrailer", trailerOption);
    }

    private static handleStoreDeliveryOption(e) {
        // clicked on one of the radio buttons
        const $target = $(e.currentTarget);
        const id = $target[0].id;
        let value = $target.val();
        const deliveryAddressId = $(".js-checkout-information--delivery-address-option").val();
        if (deliveryAddressId === "") {
            return;
        }

        value = typeof value === "undefined" ? "" : value?.toString();

        if (id.indexOf("crane") > -1) {
            window.localStorage.setItem("crane" + deliveryAddressId, value);
        }
        else if (id.indexOf("forklift") > -1) {
            window.localStorage.setItem("forklift" + deliveryAddressId, value);
        }
        else if (id.indexOf("trailer") > -1) {
            window.localStorage.setItem("trailer" + deliveryAddressId, value);
        }
    }

    private static resetInfoField(target: string) {
        const $input = $(`${target}--input`);
        const $readonly = $(`${target}--readonly`);

        $input.val("");
        $readonly.text("");

        $input.toggleClass("d-none", false);
        $input.toggleClass("d-block", true);

        $readonly.toggleClass("d-none", true);
        $readonly.toggleClass("d-block", false);
    }

    private static setInfoField(target: string, value: string, text: string) {
        const $input = $(`${target}--input`);
        const $readonly = $(`${target}--readonly`);

        $input.val(value);
        $readonly.html(text ?? "&#8212;");

        $input.toggleClass("d-none", true);
        $input.toggleClass("d-block", false);

        $readonly.toggleClass("d-none", false);
        $readonly.toggleClass("d-block", true);
    }

    private static updateDeliveryAddress($deliveryAddress: any, $pickupAddress: any) {
        $deliveryAddress.toggleClass("d-none", false);
        $deliveryAddress.toggleClass("d-block", true);

        $pickupAddress.toggleClass("d-none", true);
        $pickupAddress.toggleClass("d-block", false);
    }

    private static isSameDate(thisDate, trad) {
        var ret = false;

        if (
            trad.getFullYear() === thisDate.getFullYear() &&
            trad.getMonth() === thisDate.getMonth() &&
            trad.getDate() === thisDate.getDate()
        ) {
            ret = true;
        }

        return ret;
    }

    private static updatePickupAddress($transferTypeSelection: any, $deliveryAddress: any, $pickupAddress: any) {
        $deliveryAddress.toggleClass("d-none", true);
        $deliveryAddress.toggleClass("d-block", false);

        $pickupAddress.toggleClass("d-none", false);
        $pickupAddress.toggleClass("d-block", true);

        const pickupAddressAsJson = $transferTypeSelection.data("nt-pickup-address") as string;
        if (typeof pickupAddressAsJson === "undefined") {
            return;
        }

        $(".js-checkout-information--pickup-address-name").text(pickupAddressAsJson["name"]);
        $(".js-checkout-information--pickup-address-street").text(pickupAddressAsJson["street"]);
        $(".js-checkout-information--pickup-address-city").text(pickupAddressAsJson["city"]);
        $(".js-checkout-information--pickup-address-country").text(pickupAddressAsJson["countryName"]);
    }

    private static initDatepickerChange() {
        $("#transfer-date").on("change", Checkout.updateTransferTime);
        Checkout.updateTransferTime(null, null);
    }

    private static updateTransferTime(e, newDate: Date | null) {
        const selector = ".js-checkout-info--transfer-time";

        const $transferType = $(Checkout.TransferTypeSelector);
        const $transferTypeSelection = $transferType.find("option:selected");
        const transferTypeDefinition = $transferTypeSelection.data("nt-transfer-type-definition");

        // hide all to reduct flickering
        $(".js-pickup-time--saturday").toggle(false);
        $(".js-pickup-time--from8h").toggle(false);
        $(".js-pickup-time--from14h").toggle(false);

        if (transferTypeDefinition === "Delivery") {
            $(".js-checkout-info--transfer-time").removeClass("d-block");
            $(".js-checkout-info--transfer-time").addClass("d-none");
            return;
        }

        if (newDate == null) {
            newDate = CustomDatePicker.getSelectedDate("transfer-date");
        }

        if (newDate == null) {
            $(".js-checkout-info--transfer-time").removeClass("d-block");
            $(".js-checkout-info--transfer-time").addClass("d-none");
            return;
        }

        $(".js-checkout-info--transfer-time").removeClass("d-none");
        $(".js-checkout-info--transfer-time").addClass("d-block");

        let showSaterday = false;
        let showFrom8h = false;
        let showFrom14h = false;

        var today = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        if (newDate.getUTCDay() === 5) {
            showSaterday = true;
            showFrom14h = false;
            showFrom8h = false;
        } else {
            showSaterday = false;
            showFrom14h = false;
            showFrom8h = false;

            // if pickup was selected and ordered before or at 12, customers can collect the same day
            // but message "starting from 14h" is  shown if same day is selected
            if ((today.getHours() < 12 || (today.getHours() === 12 && today.getMinutes() === 0 && today.getSeconds() === 0)) && Checkout.isSameDate(newDate, today)) {
                showFrom14h = true;
            }
            // if pickup was selected and ordered after 12, customers can collect the day after
            // but message "starting from 8h" is  shown if tomorrow is selected
            else {
                showFrom8h = true;
            }
        }

        //// console.log(showSaterday, showFrom8h, showFrom14h);
        $(".js-pickup-time--saturday").toggle(showSaterday);
        $(".js-pickup-time--from8h").toggle(showFrom8h);
        $(".js-pickup-time--from14h").toggle(showFrom14h);
    }

    private static updateTransferDatesReal() {
        const $deliveryAdvice = $(Checkout.DeliveryAdviceSelector);
        let $deliveryAdviceSelection = $deliveryAdvice.find("option:selected");

        if ($deliveryAdviceSelection.length == 0) {
            $deliveryAdviceSelection = $deliveryAdvice;
        }

        const $transferType = $(Checkout.TransferTypeSelector);
        let $transferTypeSelection = $transferType.find("option:selected");

        if ($transferTypeSelection.length == 0) {
            $transferTypeSelection = $transferType;
        }

        const deliveryAdviceId = $deliveryAdviceSelection.val() as number;
        const transferTypeId = $transferTypeSelection.val() as number;
        // NOTE: if transferTypeId == -1 then delivery else pickup, and transferTypeId is actually the transferSubType

        let postalCode = $(".js-checkout-information--delivery-address-postal-code--input").val() as string;
        const countryId = $(".js-checkout-information--delivery-address-country--input").val() as number;
        if (postalCode === "undefined") {
            postalCode = "";
        }

        const qs = `postalCode=${postalCode}&countryId=${countryId}&deliveryAdviceId=${deliveryAdviceId}&transferTypeId=${transferTypeId}`
        if ((typeof postalCode === "undefined") ||
            (typeof deliveryAdviceId === "undefined") ||
            (typeof transferTypeId === "undefined") ||
            (typeof countryId === "undefined")) {
            return;
        }

        const url = ActionUrls.getUrl("get-transfer-date-data") + "?" + qs;

        axios
            .get<TransferDateData>(url)
            .then((res) => res.data)
            .then((data: TransferDateData) => {
                const datePickerData: DatePickerData = {
                    selectedDate: data.transferDate,
                    minDate: data.firstTransferDate,
                    maxDate: data.lastTransferDate,
                    allowedDateList: data.allowedTransferDateList
                }

                $(Checkout.TransferSubTypeSelector).val(data.transferSubTypeId);

                if (data.allowedTransferDateList.length == 0) {
                    $(Checkout.DatesAvailableSelector).toggleClass("d-none", true);
                    $(Checkout.DatesAvailableSelector).toggleClass("d-block", false);

                    $(Checkout.DatesNotAvailableSelector).toggleClass("d-block", true);
                    $(Checkout.DatesNotAvailableSelector).toggleClass("d-none", false);

                    CustomDatePicker.clearDatePicker();
                }
                else {
                    CustomDatePicker.updateDates(datePickerData, "transfer-date");

                    $(Checkout.DatesAvailableSelector).toggleClass("d-none", false);
                    $(Checkout.DatesAvailableSelector).toggleClass("d-block", true);

                    $(Checkout.DatesNotAvailableSelector).toggleClass("d-block", false);
                    $(Checkout.DatesNotAvailableSelector).toggleClass("d-none", true);
                }

                Checkout.updateTransferTime(null, null);

            }).catch((err) => {
                console.error(err);

                const defaultMessage = Multilang.getTranslation("checkout.message.error.update-transfer-dates", "Could not update transfer dates.");
                Toaster.showAxiosMessage(err, defaultMessage);
            }).finally(() => {
                Checkout.updateBasketTotals(false);
            });
    }
}
